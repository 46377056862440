import { breakpoints } from '../pattern/breakpoints';

export enum EScreen {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl'
};

export const getSreenSize = (screenWidth: number): EScreen => {
  if (screenWidth >= breakpoints['2xl']) {
    return EScreen['2xl'];
  }

  if (screenWidth >= breakpoints.lg) {
    return EScreen.lg;
  }

  if (screenWidth >= breakpoints.xl) {
    return EScreen.xl;
  }

  if (screenWidth >= breakpoints.md) {
    return EScreen.md;
  }

  if (screenWidth >= breakpoints.sm) {
    return EScreen.sm;
  }

  return EScreen.xs;
};

import React, { memo, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import Skeleton from 'react-loading-skeleton';
import _isEmpty from 'lodash/isEmpty';
import reactImageSize from 'react-image-size';
import IVideoYoutube from '../model/youtube-video-response';
import { getDate } from '../helpers/date-time';
import { youtubeTitle } from '../helpers/article-data';
import { EScreen } from '../helpers/screen-helper';
import { Link, useNavigate } from 'react-router-dom';

interface IProps {
  article: IVideoYoutube;
  isFeature?: boolean;
  overlayTitle?: boolean;
  screen?: EScreen;
  playOnNewWindow?: boolean;
}

interface IRatio {
  width: number;
  height: number;
}

const VideoCardV2: React.FC<IProps> = ({ article, isFeature = false, overlayTitle = false, screen, playOnNewWindow = false }) => {
  const wrapper: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerWidth, setPlayerWidth] = useState(0);
  const [playerHeight, setPlayerHeight] = useState(0);
  const navigate = useNavigate();
  const ratio: IRatio = useMemo(() => isFeature === false ? { width: 357, height: 255 } : screen === EScreen.md ? { width: 16, height: 9 } : { width: 1230, height: 583 }, [isFeature, screen]);
  const buttonHeight: number = useMemo(() => isFeature === true ? 90 : 53, [isFeature]);
  const isLoading: boolean = useMemo(() => _isEmpty(article) === true, [article]);
  const [finalImage, setFinalImage] = useState(`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg`);

  useEffect(() => {
    if (article) {
      reactImageSize(`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg`).then(({ width }) => {
        if (width === 120) {
          setFinalImage(article?.feature_image);
        } else {
          setFinalImage(`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg`);
        }
      })
    }

    setFinalImage(`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg`);
  }, [article]);

  useEffect(() => {
    if (wrapper !== null) {
      setPlayerWidth(wrapper.current?.clientWidth || 0);
      setPlayerHeight(Math.ceil((wrapper.current?.clientWidth || 0) * ratio.height / ratio.width));
    }
  }, [wrapper, ratio]);

  const articleCategory: string = useMemo(() => article?.hashTags[0].replace('#', ''), [article]);

  return (
    <div className='flex flex-col flex-1'>
      <div
        ref={wrapper}
        className={`relative text-jm-white ${isPlaying ? 'opacity-0 hidden' : 'opacity-100'} ${isFeature === false ? 'aspect-video-thumbnails' : (screen === EScreen.md ? 'aspect-video' : 'aspect-video-thumbnails-desktop')}`}
        style={{
          backgroundImage: `url(${finalImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          style={{ height: playerHeight }}
          className='w-full h-full relative bg-gradient-to-t from-jm-black-title via-transparent to-transparent opacity-40' />
        <div className='w-full absolute self-end flex flex-col bottom-3 px-3'>
          {
            overlayTitle === true && (
              <>
                <div className='text-14 uppercase lg:text-16 lg:leading-21'>{articleCategory || <Skeleton width={150} />}</div>
                <Link
                  className='text-card-headline sm:text-36 font-bold cursor-pointer font-heading mb-8px md:leading-48'
                  to={`/video/${article?.id}`}>
                  {youtubeTitle(article?.title) || <Skeleton count={2} />}
                </Link>
                <div className='text-14 sm:text-16 sm:leading-19'>{isLoading === false ? getDate(new Date(article?.published_at)) : <Skeleton width={130} />}</div>
              </>
            )
          }
        </div>
        <div onClick={() => playOnNewWindow === false ? setIsPlaying(true) : navigate(`/video/${article?.id}`)} className={`absolute cursor-pointer opacity-80 hover:opacity-90 transition-opacity duration-500 ${isFeature === true ? 'top-video-button-feature left-video-button-feature' : 'top-video-button left-video-button'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width={buttonHeight} height={buttonHeight} viewBox='1 1 53 53'>
            <path d="M27.945,3.375a24.57,24.57,0,1,0,24.57,24.57A24.567,24.567,0,0,0,27.945,3.375Zm9.9,25.031-16.207,9.8a.526.526,0,0,1-.791-.461V18.141a.523.523,0,0,1,.791-.461l16.207,9.8A.541.541,0,0,1,37.844,28.406Z" fill="#fff" />
          </svg>
        </div>
      </div>
      {
        isPlaying === true && (
          <div className={`w-full ${isPlaying ? 'opacity-100' : 'opacity-0 hidden'}`}>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${article?.id}`}
              width={playerWidth}
              height={playerHeight}
              playing={isPlaying}
            />
          </div>
        )
      }
      {
        overlayTitle === false && (
          <div className='flex flex-col text-jm-black mt-4'>
            <div className='text-14 uppercase'>{articleCategory || <Skeleton width={100} />}</div>
            <Link
              className='font-heading mt-1 sm:mt-0 text-card-headline text-jm-black-title font-bold'
              to={`/video/${article?.id}`}>
              {youtubeTitle(article?.title) || <Skeleton count={2} />}
            </Link>
            <div className='text-14 mt-4px lg:mt-10px'>{isLoading === false ? getDate(new Date(article?.published_at)) : <Skeleton width={130} />}</div>
          </div>
        )
      }
    </div>
  )
};

export default memo(VideoCardV2);

import { GhostAPI, PostOrPage, PostsOrPages } from '@tryghost/content-api';
import React, { memo, useEffect, useMemo, useState } from 'react';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _omit from 'lodash/omit';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import StoryCard from '../../components/story-card';
import FeedCard from '../../components/feed-card';
import withContentApi from '../../hoc/withContentApi';

interface IProps {
  title?: string;
  limit?: number;
  customTitleClasses?: string;
  excludedArticles?: PostOrPage[];
  api?: GhostAPI;
  bindToParent?: (data: PostOrPage[]) => void;
}

const RecentPublishedFeeds: React.FC<IProps> = ({ title, limit = 3, customTitleClasses, excludedArticles, api, bindToParent }) => {
  const [latestArticles, setLatestArticles] = useState<PostOrPage[]>([]);

  useEffect(() => {
    const excludedArticleIds: string[] = _map(excludedArticles, 'id');
    const articlesLength: number = limit + excludedArticleIds.length;

    api?.posts.browse({ limit: articlesLength, include: ['authors', 'tags'], order: 'published_at desc' }).then((res: PostsOrPages) => {
      setLatestArticles(_filter(_map(_omit(res, 'meta')) as PostOrPage[], (article: PostOrPage) => !_includes(excludedArticleIds, article.id)).slice(0, limit));
    });
  }, [api, excludedArticles, limit]);

  useEffect(() => {
    if (typeof bindToParent === 'function' && latestArticles.length > 0) {
      bindToParent(latestArticles);
    }
  }, [latestArticles, bindToParent]);

  const renderFeeds: PostOrPage[] = useMemo(() => _isEmpty(latestArticles) ? new Array(limit) : latestArticles, [latestArticles, limit]);

  return <>
    {
      title && <div className={`text-trending-title font-bold ${customTitleClasses ? customTitleClasses : ''}`}>{title}</div>
    }
    <div className='flex flex-col sm:hidden lg:flex divide-y divide-jm-gray-border'>
      {
        _map(renderFeeds, (article: PostOrPage, index: number) => (
          <div className='py-25px sm:py-4' key={index}>
            <FeedCard article={article} />
          </div>
        ))
      }
    </div>
    <div className='flex-col space-y-3 sm:space-y-5 hidden sm:flex lg:hidden'>
      {
        _map(renderFeeds, (article: PostOrPage, index: number) => (
          <StoryCard article={article} key={index} isCompact />
        ))
      }
    </div>
  </>;
};

export default memo(withContentApi<IProps>(RecentPublishedFeeds));

import axios, { AxiosResponse } from 'axios';
import _get from 'lodash/get';

interface ISubscribeResponse {
  message: string;
}

export const subscribe = (email: string, fromForm: string, onSuccess: (alreadySubscribe: boolean) => void, onError: () => void): void => {
  axios.post(`${_get(process.env, 'REACT_APP_MIDDLEWARE', '')}/subscribe`, { email, fromForm }).then((res: AxiosResponse<ISubscribeResponse>) => {
    onSuccess(_get(res.data, 'message', '') !== '');
  }).catch(() => onError());
}

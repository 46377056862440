import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';

import LayoutLoading from './layout-loading';
import SectionTitle from '../components/section-title';
import VideoCard from '../components/video-card';
import VideoFeedCard from '../components/video-feed-card';
import IVideoYoutube from '../model/youtube-video-response';

interface IProps {
  articles: IVideoYoutube[];
  total: number;
}

const VideoSection: React.FC<IProps> = ({ articles, total }) => {
  const [featureId, setFeatureId] = useState('');

  useEffect(() => {
    setFeatureId(_get(articles, '0.id', ''));
  }, [articles]);

  const onClickVideoFeed = useCallback((clickedId: string) => {
    setFeatureId(clickedId);
  }, []);

  const currentVideo = useMemo(() => _get(articles, _findIndex(articles, ['id', featureId])), [featureId, articles]);

  return <LayoutLoading condition={articles.length === 0}>
    <>
      <SectionTitle
        title='Videos'
        total={total}
        linkUrl='/video'
      />
      <div className='flex flex-col lg:flex-row-reverse pt-11px sm:pt-6px'>
        <div className='lg:w-2/3 lg:ml-4'>
          <VideoCard article={currentVideo} isFeature />
        </div>
        <div className='py-1 divide-y sm:flex sm:flex-row sm:space-x-3 lg:w-1/3 lg:flex-col lg:py-0 lg:space-x-0 lg:pr-2'>
          {
            _map(articles, (article: IVideoYoutube, index: number) => <div key={index} className={`pt-5 pb-30px sm:pb-4 sm:pt-34px flex-1 ${index === articles.length - 1 ? 'sm:hidden lg:block lg:pb-0' : ''} ${index === 0 ? 'lg:pt-0' : ''}`}>
              <VideoFeedCard key={article.id} article={article} onClick={onClickVideoFeed} />
            </div>)
          }
        </div>
      </div>
    </>
  </LayoutLoading>
};

export default memo(VideoSection);
import { GhostAPI, PostOrPage, PostsOrPages, Tag } from '@tryghost/content-api';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Location, useParams, useNavigate } from 'react-router-dom';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import _omit from 'lodash/omit';
import _map from 'lodash/map';
import _compact from 'lodash/compact';
import _uniqBy from 'lodash/uniqBy';
import _slice from 'lodash/slice';
import Breadcrumb from '../components/breadcrumb';
import SectionFeed from '../layout/section-feed';
import CategorySection from '../components/category-section';
import CategorySectionList from '../components/category-section-list';
import RecentPublicFeeds from '../layout/article-details/recent-public-feeds';
import { IAppContextStore, useAppContext } from '../context/app-context';
import withContentApi from '../hoc/withContentApi';
import { ArticleTag, GuideTag } from '../api-helpers/data-transformer';
import { getPrimaryTag } from '../helpers/article-data';

interface IProps {
  api?: GhostAPI;
};

enum PageType {
  Article = 'Articles',
  Guide = 'Guides'
};

interface IPageTypeSettings {
  type: PageType;
  defaultContentPrefix: string;
}

const PageSettings: IPageTypeSettings[] = [
  {
    type: PageType.Article,
    defaultContentPrefix: ArticleTag
  },
  {
    type: PageType.Guide,
    defaultContentPrefix: GuideTag
  }
];

const MaxCategoryShowing: number = 10;

const SectionFrontPage: React.FC<IProps> = ({ api }) => {
  const { allTags }: IAppContextStore = useAppContext();
  const location: Location = useLocation();
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const paramsCategory = useMemo(() => categoryId, [categoryId]);
  const [top100, setTop100] = useState<PostOrPage[]>([]);
  const pageType: PageType = useMemo(
    () => location.pathname.includes('articles') ? PageType.Article : PageType.Guide, [location]);
  const pageSettings: IPageTypeSettings = useMemo(() => _find(PageSettings, (settings: IPageTypeSettings) => settings.type === pageType) as IPageTypeSettings, [pageType]);

  const setSelectedCategory = useCallback((selectedCategory: Tag) => {
    navigate(`/${location.pathname.includes('articles') ? 'articles' : 'guides'}/category/${selectedCategory.slug}`);
  }, [location, navigate]);

  const selectedCategory: Tag = useMemo(() => {
    const isRootPage = _isEmpty(paramsCategory);

    if (isRootPage) {
      setTop100([]);
    }

    if (_isEmpty(allTags) === false) {
      if (isRootPage) {
        return { slug: pageSettings.defaultContentPrefix } as Tag;
      }
  
      return _find(allTags, (tag: Tag) => tag.slug === paramsCategory) as Tag;
    }

    return { slug: pageSettings.defaultContentPrefix } as Tag;
  }, [paramsCategory, pageSettings, allTags]);

  useEffect(() => {
    if (_isEmpty(top100) === true) {
      api?.posts.browse({ limit: 100, include: ['tags'], fields: ['title', 'slug', 'primary_tag', 'id'], filter: `tags:${pageSettings.defaultContentPrefix}`})
        .then((res: PostsOrPages) => setTop100(_map(_omit(res, 'meta'), (post: PostOrPage) => ({
          ...post,
          primary_tag: getPrimaryTag(post),
        }))));
    }
  }, [pageSettings, api, top100, setTop100]);

  const allPrimaryTags: Tag[] = useMemo(() => _slice(_uniqBy(_compact(_map<Tag>(top100, 'primary_tag')), 'slug'), 0, MaxCategoryShowing), [top100]);
  const displayingTags: Tag[] = useMemo(() => _map(allPrimaryTags, (tag: Tag) => ({ ...tag, count: { posts: _find(allTags, ['slug', tag.slug ])?.count?.posts || 0 } })), [allPrimaryTags, allTags]);

  return (
    <div className='flex flex-col'>
      <Breadcrumb data={[
        {
          text: pageType,
          link: `/${pageType.toLowerCase()}`
        },
      ]} />
      <div className='flex flex-col sm:flex-row pb-3 pt-26px sm:pt-16px'>
        <div className='sm:hidden pb-3 mb-24px'>
          <CategorySection
            type={pageType}
            tags={displayingTags}
            onSelect={(tag: Tag) => setSelectedCategory(tag)}
            selectedCategory={selectedCategory}
          />
        </div>
        <div className='sm:w-2/3 sm:flex sm:flex-col'>
          <div className='hidden sm:block font-bold mb-3 text-22 sm:mb-23px sm:text-38 lg:mb-31px'>
            {pageType}
          </div>
          <SectionFeed
            tags={typeof selectedCategory !== 'undefined' ? [selectedCategory] : []}
            className='grid grid-cols-1 gap-4 sm:gap-5 lg:grid-cols-2 lg:gap-8'
            allowLoadmore
          />
        </div>
        <div className='sm:w-1/3 sm:ml-4 lg:ml-8'>
          <div className='hidden sm:block mb-3 text-22'>
            <CategorySectionList
              type={pageType}
              tags={displayingTags}
              selectedCategory={selectedCategory}
            />
          </div>
          <div className='mt-60px sm:mt-25px lg:mt-3'>
            <RecentPublicFeeds
              title='Recent Publications'
              excludedArticles={top100}
              customTitleClasses='sm:mb-20px'
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(withContentApi(SectionFrontPage));

import React, { memo } from 'react';

interface IFooterLinkProps {
  text: string;
  url: string;
}

const FooterLink: React.FC<IFooterLinkProps> = ({ text, url }) => (
  <a href={url} target='_blank' rel='noreferrer'>{text}</a>
);

export default memo(FooterLink);

import React, { memo } from 'react';

interface IProps {
  className?: string;
}

const Arrow: React.FC<IProps> = ({ className }) => (
  <svg className={`-mt-1 ${className}`} xmlns="http://www.w3.org/2000/svg" width="12.955" height="7.407" viewBox="0 0 12.955 7.407">
    <path className='' d="M12.667,16.421,14.884,14.2l2.682-2.685a.922.922,0,0,1,1.308,0,.934.934,0,0,1,0,1.311l-3.12,3.123-2.43,2.432a.924.924,0,0,1-1.277.027l-3.036-3.03L6.457,12.834a.926.926,0,1,1,1.308-1.311Z" transform="translate(-6.188 -11.247)" />
  </svg>
)

export default memo(Arrow);

import { Tag } from '@tryghost/content-api';
import React, { memo, useMemo } from 'react';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Arrow from './arrow';

interface IProp {
  tags: Tag[];
  selectedCategory?: Tag;
  type: string;
};

interface ICategoryLineProps {
  tag: Tag;
  selected: boolean;
  type: string;
}

const CategoryLine: React.FC<ICategoryLineProps> = memo(({ tag, selected, type }) => {
  return (
    <Link
      to={`/${type.toLowerCase()}/category/${tag?.slug}`}
      className={`cursor-pointer py-2 px-3 flex border-b border-jm-gray-border ${selected ? 'bg-jm-gray-border' : ''}`}
    >
      <div className='flex-auto flex flex-row items-center capitalize'>
        <span className='mr-2'><Arrow className='-rotate-90 mt-0' /></span>
        <span className='font-segoe text-20 leading-23'>{tag?.name || <Skeleton width={(Math.random() * 100) + 200} />}</span>
      </div>
      <div>({tag?.count?.posts})</div>
    </Link>
  )
});

const CagegorySelectionList: React.FC<IProp> = ({ tags, selectedCategory, type }) => {
  const renderTags: Tag[] = useMemo(() => _isEmpty(tags) ? new Array(10) : tags, [tags]);

  return (
    <div className='flex flex-col text-jm-black-title text-16'>
      <div
        className='pt-1 pb-2 px-3 flex items-center justify-between lg:mb-31px lg:pb-0'
      >
        <div className='text-22 font-bold sm:text-28 sm:leading-25'>
          {type} Categories
        </div>
      </div>
      <div className='transition-all duration-200 flex flex-col'>
        {
          _map(renderTags, (tag: Tag, index: number) => (
            <CategoryLine
              tag={tag}
              key={index}
              selected={selectedCategory?.slug === tag?.slug}
              type={type}
            />
          ))
        }
      </div>
    </div>
  )
};

export default memo(CagegorySelectionList);

import { PostOrPage } from '@tryghost/content-api';
import React, { memo } from 'react';
import _map from 'lodash/map';

import LayoutLoading from './layout-loading';
import StoryCard from '../components/story-card';
import SectionTitle from '../components/section-title';
interface IProps {
  articles: PostOrPage[];
  total: number;
  title: string;
  oneRow?: boolean;
  showReadMore?: boolean;
  linkUrl?: string;
}

const SectionBlock: React.FC<IProps> = ({ articles, total, title, oneRow = false, showReadMore = false, linkUrl }) => (
  <LayoutLoading condition={articles.length === 0}>
    <>
      <SectionTitle
        title={title}
        total={total}
        linkUrl={linkUrl}
      />
      <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-3 lg:grid-cols-3 lg:gap-9 pb-39px sm:pb-38px sm:pt-5'>
        {
          _map(articles, (article: PostOrPage, index: number) => (
            <div key={index} className={`${oneRow === true && articles.length > 3 && index === articles.length - 1 ? 'lg:hidden' : ''}`}>
              <StoryCard article={article} />
            </div>
          ))
        }
      </div>
      {
        showReadMore === true && (
          <div className='text-center pt-3 uppercase text-jm-black-menu cursor-pointer sm:hidden'>
            <span>More</span>
          </div>
        )
      }
    </>
  </LayoutLoading>
);

export default memo(SectionBlock);
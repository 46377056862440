import React, { memo } from 'react';
import { getDate } from '../helpers/date-time';
import IVideoYoutube from '../model/youtube-video-response';
import { youtubeTitle } from '../helpers/article-data';

interface IProps {
  article: IVideoYoutube;
  onClick?: (featureId: string) => void;
}

const videoTitleClass: string = 'font-heading font-bold text-card-headline mb-2 cursor-pointer line-clamp-2';

const VideoFeedCard: React.FC<IProps> = ({ article, onClick }) => (
  <div className='flex space-x-4'>
    { onClick
        ? <div className='w-2/5 cursor-pointer' onClick={() => onClick(article.id)}>
            <img src={`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg` || ''} alt={article.title} />
          </div>
        : <div className='w-2/5 cursor-pointer'>
            <a href={`https://www.youtube.com/watch?v=${article.id}`} target='_blank' rel='noreferrer'>
              <img src={`https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg` || ''} alt={article.title} />
            </a>
          </div>
    }
    <div className='w-3/5 flex flex-col'>
      <div className='text-jm-black text-sub-heading uppercase mb-1.5'>{article?.hashTags[0]}</div>
      { onClick
          ? <div className={videoTitleClass} onClick={() => onClick(article.id)}>{youtubeTitle(article.title)}</div>
          : <a className={videoTitleClass} href={`https://www.youtube.com/watch?v=${article.id}`} target='_blank' rel='noreferrer'>
              {youtubeTitle(article.title)}
            </a>
      }
      <div className='text-jm-gray-content'>
        {article?.published_at ? getDate(new Date(article?.published_at)) : ''}
      </div>
    </div>
  </div>
);

export default memo(VideoFeedCard);

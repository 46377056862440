import React, { memo, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player/youtube';
import IVideoYoutube from '../model/youtube-video-response';
import { getDate } from '../helpers/date-time';
import { youtubeTitle } from '../helpers/article-data';

interface IProps {
  article: IVideoYoutube;
  isFeature?: boolean;
}

interface IRatio {
  width: number;
  height: number;
}

const VideoCard: React.FC<IProps> = ({ article, isFeature = false }) => {
  const wrapper: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const [isPlaying, setIsPlaying] = useState(false);
  const [playerWidth, setPlayerWidth] = useState(0);
  const [playerHeight, setPlayerHeight] = useState(0);
  const ratio: IRatio = useMemo(() => isFeature === false ? { width: 390, height: 188 } : { width: 16, height: 10 }, [isFeature]);

  useEffect(() => {
    if (wrapper !== null) {
      setPlayerWidth(wrapper.current?.clientWidth || 0);
      setPlayerHeight(Math.ceil((wrapper.current?.clientWidth || 0) * ratio.height / ratio.width));
    }
  }, [wrapper, ratio]);

  return (
    <>
      <div
        ref={wrapper}
        className={`relative text-jm-white ${isPlaying ? 'opacity-0 hidden' : 'opacity-100'} ${isFeature === true ? 'aspect-video-card' : 'aspect-thumbnails'}`}
        style={{
          backgroundImage: `url(https://i.ytimg.com/vi/${article?.id}/maxresdefault.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div
          style={{ height: playerHeight }}
          className='w-full h-full relative bg-gradient-to-t from-jm-black-title via-transparent to-transparent opacity-40' />
        <div className='w-full absolute self-end flex flex-col bottom-3 px-3'>
          <div className='text-16 uppercase'>{article?.hashTags[0]}</div>
          <a className='text-video-headline font-bold cursor-pointer' href={`https://www.youtube.com/watch?v=${article?.id}`} target='_blank' rel='noreferrer'>{youtubeTitle(article?.title)}</a>
          <div>{getDate(new Date(article?.published_at))}</div>
        </div>
        <div onClick={() => setIsPlaying(true)} className='absolute top-video-button left-video-button cursor-pointer opacity-80 hover:opacity-90 transition-opacity duration-500'>
          <svg xmlns="http://www.w3.org/2000/svg" width="49.141" height="49.141" viewBox="0 0 49.141 49.141">
            <path id="Icon_ionic-ios-play-circle" data-name="Icon ionic-ios-play-circle" d="M27.945,3.375a24.57,24.57,0,1,0,24.57,24.57A24.567,24.567,0,0,0,27.945,3.375Zm9.9,25.031-16.207,9.8a.526.526,0,0,1-.791-.461V18.141a.523.523,0,0,1,.791-.461l16.207,9.8A.541.541,0,0,1,37.844,28.406Z" transform="translate(-3.375 -3.375)" fill="#fff" />
          </svg>
        </div>
      </div>
      {
        isPlaying === true && (
          <div className={`w-full ${isPlaying ? 'opacity-100' : 'opacity-0 hidden'}`}>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${article.id}`}
              width={playerWidth}
              height={playerHeight}
              playing={isPlaying}
            />
          </div>
        )
      }
    </>
  )
};

export default memo(VideoCard);

import React, { memo, useMemo } from 'react';
import { Author } from '@tryghost/content-api';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

interface AuthorProps {
  authors: Author[];
  date: string;
  speedReading: string;
  oneAvatar?: boolean;
  noAuthor?: boolean;
}

const defaultAva: string = 'https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=928&q=80';

const AuthorCard: React.FC<AuthorProps> = ({ authors, date, speedReading, oneAvatar, noAuthor }) => {
  const isLoading = useMemo(() => _isEmpty(speedReading) === true, [speedReading]);

  return (
    <div className='flex'>
      {
        _map(authors, (author: Author, index: number) => (
          <Link
            key={index}
            className='w-10 h-10 aspect-square'
            to={`/author/${author.slug}`}>
            <img
              src={author.profile_image || defaultAva}
              alt={author?.name}
              className={`object-cover w-10 h-10 cursor-pointer rounded-full border-2 border-solid border-white ${index > 0 ? '-ml-2' : ''}`}
            />
          </Link>))
      }

      <div className={`flex self-center flex-col font-sans text-14 text-left ${noAuthor !== true ? 'px-2' : ''}`}>
        <div className={`font-medium leading-4 ${noAuthor === true ? 'hidden' : ''}`}>
          {
            _map(authors, (author: Author) => (
              <React.Fragment key={author.slug}>
                <Link to={`/author/${author.slug}`}>
                  <span key={author.slug}>{_get(author, 'name', '')}</span>
                </Link>
                <span className='mr-1 last:hidden'>,</span>
              </React.Fragment>
            ))
          }
        </div>
        <div className={`text-jm-gray-sub-content text-14 leading-4 ${oneAvatar ? 'text-12' : 'text-14'}`}>
          {isLoading === true ? <Skeleton /> : `${date} • ${speedReading}`}
        </div>
      </div>
    </div>
  )
}

export default memo(AuthorCard);

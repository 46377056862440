import { PostOrPage } from '@tryghost/content-api';
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import { readingTime } from '@tryghost/helpers/lib';
import Skeleton from 'react-loading-skeleton';
import Author from './author-card';
import { getDate } from '../helpers/date-time';
import { descriptionNoLink, generateSourceSet, getDisplayTagName, getTag } from '../helpers/article-data';
import { isGuideArticle } from '../api-helpers/data-transformer';

interface IProps {
  article: PostOrPage;
  isCompact?: boolean;
  isFeature?: boolean;
  isExpand?: boolean;
  hasEyebrow?: boolean;
  customClasses?: string;
}

const StoryCard: React.FC<IProps> = ({
  article,
  isCompact = false,
  isFeature = false,
  isExpand = false,
  customClasses
}) => {
  const isLoading: boolean = useMemo(() => _isEmpty(article) === true, [article]);
  const isGuide: boolean = useMemo(() => isGuideArticle(article), [article]);
  const url: string = useMemo(() => `/${isGuide ? 'guides' : 'articles'}/${article?.slug}`, [article, isGuide]);
  const displayDescription = useMemo(() => {
    if (article?.excerpt) {
      return article?.excerpt?.split('\n\n').join(' ');
    }

    return (article?.html || '').replaceAll('<[^>]*>', '');
  }, [article]);

  return (
    <div className={`flex h-full flex-col ${isExpand ? 'lg:grid lg:grid-cols-3 lg:gap-9' : ''} ${customClasses ? customClasses : ''}`}>
      <Link to={url} className={isExpand ? 'lg:col-span-2' : ''}>
        <div
          className={`w-full overflow-hidden ${isFeature === true ? 'aspect-main-img' : 'aspect-thumbnails'} ${isExpand ? 'mb-2 sm:mb-6 lg:mb-10' : 'mb-15px sm:mb-3 lg:mb-18px'}`}>
          <img
            className={`w-full bg-jm-slide-background object-cover scale-100 hover:scale-105 transition object-center ${isFeature === true ? 'aspect-main-img' : 'aspect-thumbnails'} ${isLoading === true ? 'animate-pulse' : ''}`}
            loading='lazy'
            sizes='(max-width: 1000px) 400px, 800px'
            srcSet={generateSourceSet(article?.feature_image || '')}
            src={article?.feature_image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='}
            alt={article?.feature_image_alt || article?.feature_image_caption || ''} />
        </div>
      </Link>
      <div className={isExpand ? 'flex justify-center flex-col' : ''}>
        <Link to={`/tags/${getTag(article)?.slug}`}>
          <div className={`text-16 uppercase ${isExpand ? 'my-2' : 'mb-10px'}`}>
            {getDisplayTagName(article) || (isLoading && <Skeleton />)}
          </div>
        </Link>
        <Link className={`font-heading font-bold capitalize text-card-headline mb-2 mt-0 sm:mt-10px lg:mt-0  ${isExpand ? 'text-34 leading-39' : ''}`} to={url}>
          {article?.title || <Skeleton />}
        </Link>
        {
          isCompact === false && (
            <div className={`mt-10px mb-17px lg:mt-9px lg:mb-15px font-segoe leading-23 text-jm-gray-content text-sort-description flex-grow lg:flex-initial line-clamp-3 ${isExpand ? 'sm:mt-2' : ''}`}>
              {descriptionNoLink(displayDescription) || <Skeleton count={3} />}
            </div>
          )
        }
        <div>
          <Author
            authors={article?.authors || []}
            date={article?.published_at ? getDate(new Date(article?.published_at)) : ''}
            speedReading={isLoading === false ? readingTime(article, { minute: 'A min read', minutes: '% mins read' }) : ''}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(StoryCard);

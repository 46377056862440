import { useState, useEffect } from 'react';
import { EScreen, getSreenSize } from '../helpers/screen-helper';

function getWindowDimensions(): EScreen {
  const { innerWidth: width } = window;

  return getSreenSize(width);
}

export default function useWindowDimensions(): EScreen {
  const [windowDimensions, setWindowDimensions] = useState<EScreen>(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

import React, { ChangeEvent, InputHTMLAttributes, KeyboardEvent, memo, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _map from 'lodash/map';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/the_parlor_logo.png';
import { useOnClickOutside } from '../custom-hooks/useClickOutside';
import FooterIcon from './footer-icon';
import FooterLink from './footer-link';

const SearchIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21.48" height="21.48" viewBox="0 0 21.48 21.48">
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(0.999 1)">
      <path id="Path_2" data-name="Path 2" d="M21.447,12.974A8.474,8.474,0,1,1,12.974,4.5a8.474,8.474,0,0,1,8.474,8.474Z" transform="translate(-4.499 -4.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path id="Path_3" data-name="Path 3" d="M29.583,29.583l-4.608-4.608" transform="translate(-10.516 -10.517)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
  </svg>
);

interface IMenuLink {
  text: string;
  path: string;
  isExternal?: boolean;
}

const headerLinks: IMenuLink[] = [
  {
    text: 'Home',
    path: '/'
  },
  {
    text: 'Blog',
    path: '/articles'
  },
  {
    text: 'Ranking',
    path: '/ranking'
  },
  {
    text: 'Learn',
    path: '/guides'
  },
  {
    text: 'Glossary',
    path: '/glossary'
  },
  {
    text: 'Shop',
    path: 'https://jomashop.com',
    isExternal: true
  },
  {
    text: 'Videos',
    path: '/video'
  }
];

const MenuLink: React.FC<{ item: IMenuLink, isActive: boolean, onClick: () => void }> = ({ item, isActive, onClick }) => (
  <div className='relative border-b-2 border-transparent pb-1 hover:border-jm-black'>
    {
      item.isExternal === true ? (
        <a className='font-bold lg:font-normal cursor-pointer' href={item.path} target='_blank' rel='noreferrer'>{item.text}</a>
      ) : (
        <a
          href={item.path} 
          onClick={(e) => {e.preventDefault(); e.stopPropagation(); onClick();}}
          className={`font-bold cursor-pointer ${isActive ? 'text-jm-red lg:text-jm-black lg:font-bold' : 'lg:font-normal'}`}>
          {item.text}
        </a>
      )
    }
  </div>
);

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement>{
  currentValue: string;
  onSetValue: (value: string) => void;
  onEnter: () => void;
}

const SearchInput = React.forwardRef<HTMLInputElement, ISearchInputProps>((props, ref) => {
  const { currentValue, onSetValue, onEnter, ...others }: ISearchInputProps = props;
  const uniqueId = useMemo(() => `header-search-box-${Math.random() * 1000}`,[]);

  return (
    <>
      <label className='hidden' htmlFor={uniqueId}>Search Box</label>
      <input
        id={uniqueId}
        value={currentValue}
        onChange={(event: ChangeEvent<HTMLInputElement>): void => onSetValue(event.target.value)}
        placeholder='Search by brand or model'
        onKeyDown={(event: KeyboardEvent<HTMLInputElement>): void => {
          if (event.key === 'Enter') {
            onEnter();
          }
        }}
        {...others}
        {...ref && { ref }}
      />
    </>
  )
});

const Header: React.FC = () => {
  const [openNav, setOpenNav] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showMobileSearchInput, setShowMobileSearchInput] = useState(false);
  const menuArea: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const location = useLocation();
  const navigate = useNavigate();
  const currentMenuIndex = useMemo(() => {
    if (location.pathname === '/') {
      return 0;
    }

    return _findIndex(headerLinks, (item: IMenuLink) => location.pathname.startsWith(item.path), 1);
  }, [location]);

  useOnClickOutside(menuArea, () => setOpenNav(false));

  useEffect(() => {
    if (openNav === true) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    }

    return () => {
      document.getElementsByTagName('body')[0].style.overflow = 'auto';
    };
  }, [openNav]);

  const onClickAMenuLink = useCallback((link: IMenuLink) => navigate(link.path), [navigate]);
  const searchInputMobileRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const searchInputMobile: RefObject<HTMLInputElement> = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>;
  useOnClickOutside(searchInputMobileRef, () => setShowMobileSearchInput(false));

  useEffect(() => {
    if (showMobileSearchInput === true) {
      searchInputMobile.current?.focus();
    }
  }, [showMobileSearchInput, searchInputMobile]);

  const gotoSearchPage = useCallback(() => {
    if (_isEmpty(searchTerm) === false) {
      navigate(`/search-results?q=${encodeURIComponent(searchTerm)}`);
      setSearchTerm('');
    }

    setShowMobileSearchInput(false);
  }, [searchTerm, setSearchTerm, navigate, setShowMobileSearchInput]);

  return (
    <>
      <div ref={searchInputMobileRef} className='relative flex justify-between pt-26px pb-26px sm:pt-23px sm:pb-23px lg:pt-42px lg:pb-29px border-jm-gray-content border-b border-opacity-40 items-center sm:border-0 lg:border-b'>
        <div className='lg:hidden' onClick={() => setOpenNav(!openNav)}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`transition-transform duration-500 ${openNav ? 'rotate-90' : ''}`} width="24.534" height="17.356" viewBox="0 0 24.534 17.356">
            <g transform="translate(-3 -7.5)">
              <path id="Path_100" data-name="Path 100" d="M4.5,18H26.034" transform="translate(0 -1.822)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_101" data-name="Path 101" d="M4.5,9H26.034" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
              <path id="Path_102" data-name="Path 102" d="M4.5,27H26.034" transform="translate(0 -3.644)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </g>
          </svg>
        </div>
        <div className='cursor-pointer' onClick={() => navigate('/')}>
          <img style={{ height: 30 }} className='lg:hidden object-cover object-center' src={Logo} alt='Jomashop Blog' />
          <img style={{ height: 30 }} className='hidden lg:block object-cover object-center' src={Logo} alt='Jomashop Blog' />
        </div>
        <div className='hidden lg:flex lg:space-x-7 xl:space-x-11' >
          {
            _map(headerLinks, (item: IMenuLink, index: number) => index > 0 && (
              <MenuLink key={index} item={item} isActive={index === currentMenuIndex} onClick={() => onClickAMenuLink(item)} />
            ))
          }
        </div>
        <div className='hidden lg:block'>
          <div className='relative'>
            <SearchInput
              currentValue={searchTerm}
              onSetValue={setSearchTerm}
              className='transition-all duration-300 lg:w-search-bar pr-12 py-2 flex-1 border-b border-jm-gray-content outline-none focus:border-opacity-100 placeholder:text-jm-gray-content'
              onEnter={gotoSearchPage}
            />
            <button
              disabled={_isEmpty(searchTerm) === true}
              className='absolute top-2 right-0'
              onClick={() => gotoSearchPage()}
            ><span className='hidden'>Search</span>
              <SearchIcon />
            </button>
          </div>
        </div>

        <div
          className={`transition-all duration-300 absolute right-0 top-12 z-1 overflow-hidden ${showMobileSearchInput ? 'w-full' : 'w-0'}`}>
          <SearchInput
            currentValue={searchTerm}
            onSetValue={setSearchTerm}
            className='transition-all duration-300 px-3 py-2 bg-jm-light w-full outline-none focus:outline focus:outline-jm-gray-content placeholder:text-jm-gray-content'
            onEnter={gotoSearchPage}
            ref={searchInputMobile}
          />
        </div>
        <button
          onClick={() => showMobileSearchInput ? gotoSearchPage() : setShowMobileSearchInput(true)}
          className={`transition-all duration-300 sm:opacity-0 lg:hidden z-10 ${showMobileSearchInput ? 'mr-2' : ''}`}>
            <span className='hidden'>Search</span>
          <SearchIcon />
        </button>

      </div>
      <div className='relative hidden sm:flex lg:hidden'>
        <SearchInput
          currentValue={searchTerm}
          onSetValue={setSearchTerm}
          className='transition-all duration-300 px-3 py-2 bg-jm-light flex-1 outline-none focus:outline focus:outline-jm-gray-content placeholder:text-jm-gray-content'
          onEnter={gotoSearchPage}
        />
        <button disabled={_isEmpty(searchTerm) === true} className='absolute top-2 right-3' onClick={() => gotoSearchPage()}>
          <span className='hidden'>Search</span>
          <SearchIcon />
        </button>
      </div>
      <div className={`transition-all z-10 duration-500 absolute top-0 left-0 bg-jm-slide-background h-screen bg-opacity-60 ${openNav ? 'w-full opacity-100' : 'w-0 opacity-0'}`}>
        <div
          ref={menuArea}
          className='w-9/12 sm:w-4/12 bg-jm-nav-gray opacity-100 h-screen overflow-hidden flex flex-col'>
          <div className='px-5 py-10 bg-jm-white flex flex-col space-y-3'>
          {
            _map(headerLinks, (item: IMenuLink, index: number) => (
              <MenuLink key={index} item={item} isActive={index === currentMenuIndex}  onClick={() => {
                setOpenNav(false);
                onClickAMenuLink(item)
              }}  />
            ))
          }
          </div>
          <div className='flex-auto flex flex-col justify-end p-5 pb-7 text-14'>
            <div className='flex flex-row space-x-2 mb-5'>
              <FooterIcon links={['https://www.facebook.com/JomaShop', 'https://twitter.com/Jomashop', 'https://www.instagram.com/jomashop/', 'https://www.pinterest.com/jomashop/']} />
            </div>
            <div>
              <FooterLink text='Terms & Conditions' url='https://help.jomashop.com/articles/61058-terms-conditions' />
            </div>
            <div className='flex space-x-2 py-3'>
              <FooterLink text='Privacy Policy' url='https://help.jomashop.com/articles/61059-privacy-policy' />
              <FooterLink text='Careers' url=' https://jobs.jomashop.com' />
            </div>
            <div>© 1999-{new Date().getFullYear()} JOMASHOP.</div>
          </div>
        </div>
      </div>
    </>
  )
};

export default memo(Header);

import React, { memo } from 'react';
import Carousel, { CarouselProps } from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";

interface IProps {
  carouselItems: JSX.Element[];
  isFullWidth?: boolean;
  customWrapperClass?: string;
  customCarouselProps?: CarouselProps;
};

interface IArrowProps {
  onClick?: any;
  isLeft: boolean;
  isFullWidth?: boolean;
}

const CustomArrow: React.FC<IArrowProps> = ({ onClick, isLeft, isFullWidth }) => (
  <div className={`cursor-pointer absolute top-20% ${isLeft ? (isFullWidth ? '-left-4' : 'left-0') : (isFullWidth ? 'right-1' : 'right-5')}`} onClick={() => onClick()}>
    {isLeft
      ? <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
        <g id="Group_1096" data-name="Group 1096" transform="translate(0 -955)">
          <g id="Group_1094" data-name="Group 1094" transform="translate(0 -5.395)">
            <rect id="Rectangle_562" data-name="Rectangle 562" width="41" height="41" transform="translate(0 960.395)" />
            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M7.6,6.068,13.344.319a1.081,1.081,0,0,1,1.533,0,1.1,1.1,0,0,1,0,1.538L8.368,8.37a1.084,1.084,0,0,1-1.5.032L.317,1.861A1.086,1.086,0,0,1,1.85.323Z" transform="translate(24.784 972.648) rotate(90)" fill="#fff" />
          </g>
        </g>
      </svg>
      : <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
        <g id="Group_1097" data-name="Group 1097" transform="translate(-727 -955)">
          <g id="Group_1095" data-name="Group 1095" transform="translate(768 1956.395) rotate(180)">
            <rect id="Rectangle_562" data-name="Rectangle 562" width="41" height="41" transform="translate(0 960.395)" />
            <path id="Icon_ionic-ios-arrow-down" data-name="Icon ionic-ios-arrow-down" d="M7.6,6.068,13.344.319a1.081,1.081,0,0,1,1.533,0,1.1,1.1,0,0,1,0,1.538L8.368,8.37a1.084,1.084,0,0,1-1.5.032L.317,1.861A1.086,1.086,0,0,1,1.85.323Z" transform="translate(24.784 972.648) rotate(90)" fill="#fff" />
          </g>
        </g>
      </svg>
    }
  </div>
);

const CustomCarousel: React.FC<IProps> = ({ carouselItems, isFullWidth, customWrapperClass }) => (
  <div className={customWrapperClass ? customWrapperClass : ''}>
    <Carousel
      swipeable
      ssr
      arrows
      infinite
      autoPlay={false}
      shouldResetAutoplay={false}
      responsive={{
        tablet: {
          breakpoint: {
            max: 1024,
            min: 640
          },
          items: 2,
          partialVisibilityGutter: 30
        }
      }}
      customLeftArrow={<CustomArrow isLeft={true} isFullWidth={isFullWidth} />}
      customRightArrow={<CustomArrow isLeft={false} isFullWidth={isFullWidth} />}
    >
      {carouselItems}
    </Carousel>
  </div>
);

export default memo(CustomCarousel);

import React, { ChangeEvent, memo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { IAppContextStore, useAppContext } from '../context/app-context';
import FooterIcon from './footer-icon';
import FooterLink from './footer-link';

const Footer: React.FC = () => {
  const [text, setText] = useState('');
  const { onSubscribe, hasSubscribed, subscribeText }: IAppContextStore = useAppContext();

  return (
    <div className='bg-jm-light px-16px text-center lg:px-0'>
      <div className='py-40px border-b border-jm-gray-content text-16 lg:flex'>
        <div className='lg:flex-grow lg:text-left'>
          <div>Sign up for exclusive email offers & more</div>
          <div className='w-full sm:max-w-subscribe-width mx-auto flex my-4 lg:max-w-subscribe-width lg:ml-0'>
            {
              hasSubscribed ? (
                <div className='mx-auto lg:ml-0'>{subscribeText}</div>
              ) : (
                <>
                  <label className='hidden' htmlFor='input-email-footer'>Your email here</label>
                  <input
                    id='input-email-footer'
                    className='p-2 flex-grow'
                    type='email'
                    placeholder='Enter your email address here'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                  />
                  <button
                    disabled={_isEmpty(text) === true}
                    onClick={() => onSubscribe(text, 'footer')}
                    className='bg-jm-black text-jm-white px-3 sm:px-5'>Subscribe</button>
                </>
              )
            }
          </div>
        </div>
        <div className='lg:flex-auto lg:mr-0 lg:text-right'>
          <div>Follow us on social media</div>
          <div className='flex justify-center lg:justify-end space-x-3 mt-4'>
            <FooterIcon links={['https://www.facebook.com/JomaShop', 'https://twitter.com/Jomashop', 'https://www.instagram.com/jomashop/', 'https://www.pinterest.com/jomashop/']} />
          </div>
        </div>
      </div>
      <div className='py-20px text-14 flex flex-col sm:flex-row sm:justify-between'>
        <div className='flex justify-center space-x-3'>
          <FooterLink text='Terms & Conditions' url='https://help.jomashop.com/hc/en-us/articles/11923120092571-Terms-Conditions' />
          <FooterLink text='Privacy Policy' url='https://help.jomashop.com/hc/en-us/articles/19861667238939-Privacy-Policy' />
          <FooterLink text='Careers' url='https://careers.lesshire.com/jomashop' />
        </div>
        <div className='uppercase mt-5 sm:mt-0'>© 1999-{new Date().getFullYear()} JOMASHOP. ALL RIGHTS RESERVED.</div>
      </div>
    </div>
  )
};

export default memo(Footer);

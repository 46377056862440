import React, { memo, RefObject, useRef } from 'react';
import { useOnClickOutside } from '../custom-hooks/useClickOutside';

interface IProps {
  show: boolean;
  onHide: () => void;
  children?: React.ReactChild;
  wrapperClass?: string;
  noFill?: boolean;
  noCloseButton?: boolean;
}

const Modal: React.FC<IProps> = ({ show, onHide, children, wrapperClass = '', noFill = false, noCloseButton = false }) => {
  const modalBody: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  useOnClickOutside(modalBody, () => onHide());

  if (show === false) {
    return <></>
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex flex-col items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div ref={modalBody} className={`w-full relative inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full ${wrapperClass}`}>
          {
            noCloseButton === false && (
              <div className='cursor-pointer absolute top-12 right-2 border-solid border-red-500 z-20' onClick={() => onHide()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20.516" height="20.516" viewBox="0 0 20.516 20.516">
                  <path id="Icon_ionic-md-close-circle" data-name="Icon ionic-md-close-circle" d="M13.633,3.375A10.258,10.258,0,1,0,23.891,13.633,10.222,10.222,0,0,0,13.633,3.375Zm5.129,13.951-1.436,1.436-3.693-3.693L9.94,18.762,8.5,17.326,12.2,13.633,8.5,9.94,9.94,8.5,13.633,12.2,17.326,8.5,18.762,9.94l-3.693,3.693Z" transform="translate(-3.375 -3.375)" fill={noFill === false ? '#fff' : ''} />
                </svg>
              </div>
            )
          }
          {children}
        </div>
      </div>
    </div>
  );
}

export default memo(Modal);

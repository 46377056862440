import React, { ChangeEvent, memo, useMemo, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import BgLg from '../assets/jm-logo-lg.png';
import BgSm from '../assets/jm-logo-sm.png';
import BgXs from '../assets/jm-logo-xs.png';
import { IAppContextStore, useAppContext } from '../context/app-context';
import { EScreen } from '../helpers/screen-helper';
import useWindowDimensions from '../custom-hooks/useWindowDimensions';

interface IProps {
  isModalView?: boolean;
}

const SubscribeCTA: React.FC<IProps> = ({ isModalView = false }) => {
  const { hasSubscribed, onSubscribe, subscribeText }: IAppContextStore = useAppContext();
  const [text, setText] = useState('');
  const uniqueId = useMemo(() => `subscribe-email-input-${Math.random() * 1000}`, []);
  const screenSize: EScreen = useWindowDimensions();
  const logo = useMemo(() => {
    switch (screenSize) {
      case EScreen.xs:
        return BgXs;
      case EScreen.xl:
      case EScreen.md:
        return BgSm;
      default:
        return BgLg;
    }
  }, [screenSize]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          marginTop: 35,
          marginBottom: 32
        }}
        className='flex h-208 sm:h-188 relative overflow-hidden mt-52px'
      >
        <div className='absolute w-full h-full flex flex-col text-jm-white justify-center items-center'>
          {
            hasSubscribed === true ? (
              <div className='text-22 font-bold text-center'>{subscribeText}</div>
            ) : (
              <>
                <div>Subscribe to our blog</div>
                <div className='text-22 font-bold uppercase'>Stay updated</div>
                {
                  isModalView === false && (
                    <div className='flex mt-4 sm:w-3/5 lg:w-1/3'>
                      <label className='hidden' htmlFor={uniqueId}>Input Your email</label>
                      <input
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                        className='p-3 bg-jm-white bg-opacity-20 text-jm-white placeholder-jm-white text-12 flex-auto'
                        placeholder='Enter your email'
                        id={uniqueId}
                      />
                      <button
                        disabled={_isEmpty(text) === true}
                        onClick={() => onSubscribe(text, 'middle-banner')}
                        className='bg-jm-white text-jm-black-title px-3 lg:px-8 text-13 uppercase font-medium'>Subscribe</button>
                    </div>
                  )
                }
              </>
            )
          }
        </div>
      </div>
      {
        isModalView === true && hasSubscribed === false && (
          <div className='bg-jm-white flex flex-col -mt-32px'>
            <div className='flex p-5'>
              <label className='hidden' htmlFor={uniqueId}>Input Your email</label>
              <input
                onChange={(event: ChangeEvent<HTMLInputElement>) => setText(event.target.value)}
                className='p-3 border border-jm-gray-content text-12 flex-auto'
                placeholder='Enter your email'
                id={uniqueId}
              />
              <button
                onClick={() => onSubscribe(text, 'pop-up')}
                disabled={_isEmpty(text) === true}
                className='bg-jm-black text-jm-white px-3 lg:px-8 text-13 uppercase font-medium'>Subscribe</button>
            </div>
          </div>
        )
      }
    </>
  )
};

export default memo(SubscribeCTA);

import { PostOrPage, Tag } from '@tryghost/content-api';
import { createContext, useContext } from 'react';

export interface IAppContextStore {
  hasSubscribed: boolean;
  onSubscribe: (email: string, fromForm: string) => void;
  allPosts: PostOrPage[];
  subscribeText: string;
  allTags: Tag[];
  trendingPosts: PostOrPage[];
  newToTimePiecesPosts: PostOrPage[];
}

export const AppContext = createContext<IAppContextStore>({
  hasSubscribed: false,
  onSubscribe: (_email: string, _fromForm: string) => {},
  allPosts: [],
  subscribeText: '',
  allTags: [],
  trendingPosts: [],
  newToTimePiecesPosts: []
});

export const useAppContext = () => useContext(AppContext);

import { Tag } from '@tryghost/content-api';
import React, { memo, RefObject, useRef, useState } from 'react';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import LayoutLoading from '../layout/layout-loading';
import Arrow from './arrow';

interface IProp {
  tags: Tag[];
  onSelect: (tag: Tag) => void;
  selectedCategory?: Tag;
  type: string;
};

interface ICategoryLineProps {
  tag: Tag;
  forwardRef?: RefObject<HTMLDivElement>;
  onClickItem: () => void;
  selected: boolean;
}

const CategoryLine: React.FC<ICategoryLineProps> = memo(({ tag, forwardRef, onClickItem, selected }) => {
  return (
    <div
      onClick={() => onClickItem()}
      className={`cursor-pointer py-2 px-3 flex ${selected ? 'bg-jm-gray-border' : ''}`}
      {...forwardRef && { ref: forwardRef }}
    >
      <div className='flex-auto capitalize'>{tag?.name}</div>
      <div>({tag?.count?.posts})</div>
    </div>
  )
});

const CategorySection: React.FC<IProp> = ({ tags, onSelect, selectedCategory, type }) => {
  const [expanded, setExpanded] = useState(false);
  const oneItem: RefObject<HTMLDivElement> = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;

  return (
    <LayoutLoading condition={_isEmpty(tags) === true}>
      <div className={`flex flex-col text-jm-black-title border-jm-gray-border border text-16 ${expanded ? 'pb-20px' : ''}`}>
        <div
          className='font-bold border-b border-jm-gray-border pt-2 pb-1 px-3 flex items-center justify-between'
          onClick={() => setExpanded(!expanded)}
        >
          <div>
            {type} Categories
          </div>
          <div>
            <Arrow className={`transition-transform duration-200 ${expanded ? 'rotate-180' : ''}`} />
          </div>
        </div>
        <div style={{
          height: expanded === true && oneItem.current
          ? oneItem.current.clientHeight < 20 ? 'auto' : (oneItem.current.clientHeight) * tags.length
          : 0
        }} className='transition-all duration-200 flex flex-col overflow-hidden sm:mb-0'>
          {
            _map(tags, (tag: Tag, index: number) => (
              <CategoryLine
                tag={tag}
                key={index}
                selected={selectedCategory?.slug === tag.slug}
                {...index === 0 && { forwardRef: oneItem }}
                onClickItem={() => {
                  onSelect(tag);
                  setExpanded(false);
                }}
              />
            ))
          }
        </div>
      </div>
    </LayoutLoading>
  )
};

export default memo(CategorySection);

import axios, { AxiosPromise } from 'axios';
import _get from 'lodash/get';

export interface IRecommendProduct {
  brand: string;
  name: string;
  photo: string;
  price: string;
  url: string;
}

export const getPostTrending = (): AxiosPromise<string[]> => axios.get(`${_get(process.env, 'REACT_APP_MIDDLEWARE', '')}/trending`);

export const searchForRecommend = (tagString: string, limit: number = 2): AxiosPromise<IRecommendProduct[]> => (
  axios.get(`${_get(process.env, 'REACT_APP_MIDDLEWARE', '')}/recommend?q=${tagString}&limit=${limit}`)
);

import { GhostAPI, PostOrPage, PostsOrPages } from '@tryghost/content-api';
import React, { memo, useEffect, useState } from 'react';
import _omit from 'lodash/omit';
import withContentApi from '../hoc/withContentApi';
import HomePage3Up from '../layout/homepage-3up';
import SectionBlock from '../layout/section-block';
import VideoSection from '../layout/video-section';
import SubscribeCTA from '../components/subscribe-area';
import Modal from '../components/modal';
import { IAppContextStore, useAppContext } from '../context/app-context';
import IVideoYoutube, { IGetVideosResponse } from '../model/youtube-video-response';
import { getVideo } from '../api-helpers/youtube-video';
import { AxiosResponse } from 'axios';
import SectionFeed from '../layout/section-feed';
import { Helmet } from 'react-helmet';
interface IProps {
  api?: GhostAPI;
};

const HomePage: React.FC<IProps> = ({ api }) => {
  const [threeUpArticles, setThreeUpArticles] = useState<PostOrPage[]>([]);
  const [videoData, setVideoData] = useState<IVideoYoutube[]>([]);
  const [showSubModal, setShowSubModal] = useState(false);
  const { hasSubscribed, trendingPosts, newToTimePiecesPosts }: IAppContextStore = useAppContext();

  useEffect(() => {
    api?.posts.browse({ limit: 4, include: ['authors', 'tags'], order: 'published_at desc' }).then((response: PostsOrPages) => {
      setThreeUpArticles(_omit(response, 'meta'));
    });
  }, [api]);

  useEffect(() => {
    getVideo().then((res: AxiosResponse<IGetVideosResponse | IVideoYoutube[]>) => {
      const responseData = res.data as IVideoYoutube[];
      setVideoData(responseData);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>The Parlor - Blog by Jomashop</title>
        <meta name='description' content='Jomashop Blog is your goto for news, reviews, and industry insights on watches, handbags, sunglasses, fragrances, and luxury fashion clothing shoes and accessories.' />
        <link rel='canonical' href='https://www.jomashop.com/blog' />
      </Helmet>
      <div className=''>
        <HomePage3Up articles={threeUpArticles} />
        <div className='pt-2 sm:pt-0 lg:pt-2 space-y-4'>
          <SectionFeed
            tags={[]}
            posts={trendingPosts}
            sectionTitle='Trending'
            className='grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-y-6 sm:gap-x-3 lg:grid-cols-3 lg:gap-x-9 lg:gap-y-33px lg:pb-40px'
            sectionPath='/trending'
          />
          <SubscribeCTA />
          <VideoSection
            articles={videoData.slice(0, 3)}
            total={videoData.length}
          />
          <SectionBlock
            articles={newToTimePiecesPosts?.slice(0, 4)}
            total={newToTimePiecesPosts.length}
            title='Beginner Watch Guides'
            oneRow
            linkUrl='/beginner-watch-guides'
          />
        </div>
        {
          hasSubscribed === false && (
            <>
              <div onClick={() => setShowSubModal(true)} className='fixed right-4 bottom-4 rounded-2xl bg-jm-black text-jm-white px-4 py-2 flex space-x-3 items-center sm:hidden'>
                <span><svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="17.867" viewBox="0 0 13.5 17.867">
                  <g transform="translate(16652.75 -21176.25)">
                    <circle cx="4" cy="4" r="4" transform="translate(-16650 21177)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path d="M3,19.367V17.911C3,16.3,4.791,15,7,15h4c2.209,0,4,1.3,4,2.911v1.456" transform="translate(-16655 21174)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                </svg></span>
                <span>Subscribe now</span>
              </div>
              <Modal show={showSubModal} onHide={() => setShowSubModal(false)}>
                <SubscribeCTA isModalView />
              </Modal>
            </>
          )
        }
      </div>
    </>
  )
};

export default memo(withContentApi<IProps>(HomePage));

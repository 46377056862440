import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _map from 'lodash/map';
import { AxiosResponse } from 'axios';
import Breadcrumb from '../components/breadcrumb';
import IVideoYoutube, { IGetVideosResponse } from '../model/youtube-video-response';
import { getVideo } from '../api-helpers/youtube-video';
import VideoCardV2 from '../components/video-card-v2';
import SectionTitle from '../components/section-title';
import { useSearchParams } from 'react-router-dom';
import { EScreen } from '../helpers/screen-helper';
import ThePagination from '../components/pagination';
import useWindowDimensions from '../custom-hooks/useWindowDimensions';
import LinkRels from '../components/link-rels';
import useWatchPagination from '../custom-hooks/useWatchPagination';

interface IProps {
};

const MobileVideoPerPage: number = 3;
const TabletVideoPerPage: number = MobileVideoPerPage * 2;
const DesktopVideoPerPage: number = MobileVideoPerPage * 3;

const VideoPage: React.FC<IProps> = () => {
  const [videos, setVideos] = useState<IVideoYoutube[]>([]);
  const [fetched, setFetched] = useState(false);
  const restVideos: IVideoYoutube[] = useMemo(() => videos.length > 0 ? videos.slice(3) : videos, [videos]);
  const screenSize: EScreen = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileVideo, setMobileVideo] = useState<IVideoYoutube[]>([]);
  const [desktopVideo, setDesktopVideo] = useState<IVideoYoutube[]>([]);
  const currentPage = useMemo(() => parseInt(searchParams.get('page') || '1'), [searchParams]);
  const showLoadMore: boolean = useMemo(() => mobileVideo.length < restVideos.length, [mobileVideo, restVideos]);

  const itemPerPage: number = useMemo(() => {
    switch (screenSize) {
      case EScreen.md:
        return TabletVideoPerPage;
      default:
        return DesktopVideoPerPage;
    }
  }, [screenSize]);

  const totalPages = useMemo(() => Math.ceil(restVideos.length / itemPerPage), [restVideos, itemPerPage]);

  const setCurrentPage = useCallback((newPage: number) => {
    setSearchParams({ page: `${newPage}` });
  }, [setSearchParams]);

  useEffect(() => {
    setMobileVideo(restVideos.slice(0, MobileVideoPerPage * currentPage));
    const fromPage: number = currentPage - 1;
    // setTabletVideo(restVideos.slice(fromPage * TabletVideoPerPage, currentPage * TabletVideoPerPage));
    setDesktopVideo(restVideos.slice(fromPage * itemPerPage, currentPage * itemPerPage));
  }, [restVideos, currentPage, itemPerPage]);

  useEffect(() => {
    getVideo().then((res: AxiosResponse<IVideoYoutube[] | IGetVideosResponse>) => {
      setVideos(res.data as IVideoYoutube[]);
    }).finally(() => setFetched(true));
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useWatchPagination({
    page: currentPage === 0 ? 1 : currentPage,
    totalPages,
    enabled: fetched,
  });

  return (
    <div className='flex flex-col'>
      {videos?.length && (
        <LinkRels
          page={currentPage === 0 ? 1 : currentPage}
          totalPages={totalPages}
        />
      )}
      <Breadcrumb data={[
        {
          text: 'Videos',
          link: '/video'
        },
      ]} />
      <div>
        <div className='mt-16px mb-4 sm:hidden'>
          <VideoCardV2 article={videos[0]} overlayTitle playOnNewWindow />
        </div>
        <div className='hidden mt-24px sm:block'>
          <VideoCardV2 article={videos[0]} overlayTitle isFeature screen={screenSize} playOnNewWindow />
        </div>
        <SectionTitle title='Featured Videos' total={0} />
        <div className='mt-3 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-5 lg:space-x-10'>
          <VideoCardV2 article={videos[1]} playOnNewWindow />
          <VideoCardV2 article={videos[2]} playOnNewWindow />
        </div>
        <SectionTitle title='All Videos' total={0} />
        <div className='flex flex-col space-y-3 sm:hidden mb-26px sm:mb-0'>
          {
            _map(mobileVideo, (video: IVideoYoutube) => (
              <VideoCardV2 article={video} key={video?.id} playOnNewWindow />
            ))
          }
          {
            showLoadMore === true && (
              <div className='pt-8 pb-6 text-center'>
                <button
                  className='bg-jm-black text-jm-white px-12 py-2'
                  onClick={() => setCurrentPage(currentPage + 1)}>Load More</button>
              </div>
            )
          }
        </div>
        <div className='hidden sm:flex flex-col'>
          <div className='sm:grid sm:gap-20px sm:grid-cols-2 lg:grid-cols-3 lg:gap-10'>
            {
              _map(desktopVideo, (video: IVideoYoutube) => <VideoCardV2 article={video} key={video?.id} playOnNewWindow />)
            }
          </div>
          <div className='mt-56px lg:mt-66px mb-6'>
            <ThePagination
              currentPage={currentPage === 0 ? 1 : currentPage}
              totalItems={restVideos.length}
              itemPerPage={itemPerPage}
              onChangePage={(page: number) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  )
};

export default memo(VideoPage);

import React, { memo } from 'react';
import { PostOrPage } from '@tryghost/content-api';
import StoryCard from '../components/story-card';
import CustomCarousel from '../components/carousel';
import './homepage-3up.css';

interface IProps {
  articles: PostOrPage[];
}

const HomePage3Up: React.FC<IProps> = ({ articles }) => (
  <div className='w-full'>
    <div>
      <StoryCard article={articles[0]} isExpand />
    </div>
    <div className='lg:flex lg:items-stretch lg:space-x-9 sm:hidden '>
      <div className='my-25px sm:my-0 sm:flex-1'>
        <StoryCard article={articles[1]} />
      </div>
      <div className='sm:flex-1'>
        <StoryCard article={articles[2]} />
      </div>
      <div className='sm:flex-1'>
        <StoryCard article={articles[3]} />
      </div>
    </div>
    <CustomCarousel
      isFullWidth
      customWrapperClass='hidden sm:block lg:hidden mt-6 sm:mt-38px lg:mt-6 w-screen-has-margin home-slider relative'
      carouselItems={[
        <StoryCard key='carousel-1' customClasses='pr-5' article={articles[1]} />,
        <StoryCard key='carousel-2' customClasses='pr-5' article={articles[2]} />,
        <StoryCard key='carousel-3' customClasses='pr-5' article={articles[3]} />
      ]}
    />
  </div>
);

export default memo(HomePage3Up);

import { PostOrPage } from '@tryghost/content-api';
import { readingTime } from '@tryghost/helpers/lib';
import { Link } from 'react-router-dom';
import React, { memo, useMemo } from 'react';
import _isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { getDate } from '../helpers/date-time';
import AuthorCard from './author-card';
import { isGuideArticle } from '../api-helpers/data-transformer';
import { generateSourceSet } from '../helpers/article-data';

interface IProps {
  article: PostOrPage;
  noAuthor?: boolean;
}

const FeedCard: React.FC<IProps> = ({ article, noAuthor }) => {
  const isLoading: boolean = useMemo(() => _isEmpty(article) === true, [article]);
  const isGuide: boolean = useMemo(() => isGuideArticle(article), [article]);
  const url: string = useMemo(() => `/${isGuide ? 'guides' : 'articles'}/${article?.slug}`, [article, isGuide]);

  return (
    <div className='flex space-x-4'>
      <div className='w-2/5'>
        <Link to={url} className='aspect-main-img block overflow-hidden'>
          <img
            className={`w-full aspect-main-img scale-100 hover:scale-105 transition bg-jm-slide-background object-cover object-center ${isLoading === true ? 'animate-pulse' : ''}`}
            loading='lazy'
            sizes='(max-width: 1000px) 400px, 800px'
            srcSet={generateSourceSet(article?.feature_image || '')}
            src={article?.feature_image || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='}
            alt={article?.feature_image_alt || article?.feature_image_caption || (isLoading ? '' : 'Jomashop')}
          />
        </Link>
      </div>
      <div className='w-3/5 flex flex-col'>
        <Link to={`/tags/${article?.primary_tag?.slug}`}>
          <div className='text-jm-black text-sub-heading uppercase'>
            {article?.primary_tag?.name || (isLoading &&  <Skeleton />)}
          </div>
        </Link>
        <Link to={url}>
          <div className='font-heading font-bold capitalize text-16 mb-1 sm:text-21 sm:leading-27'>
            {article?.title || (isLoading && <Skeleton />)}
          </div>
        </Link>
        <AuthorCard
          noAuthor={noAuthor}
          authors={article?.authors || []}
          date={article?.published_at ? getDate(new Date(article?.published_at)) : ''}
          speedReading={isLoading === false ? readingTime(article, { minute: 'A min read', minutes: '% mins read' }) : ''}
          oneAvatar
        />
      </div>
    </div>
  );
};

export default memo(FeedCard);

import axios, { AxiosPromise } from 'axios';
import _get from 'lodash/get';
import IVideoYoutube, { IGetVideosResponse } from '../model/youtube-video-response';

export const getVideo = (limit: number = 0): AxiosPromise<IGetVideosResponse | IVideoYoutube[]> => axios.get(`${_get(process.env, 'REACT_APP_MIDDLEWARE', '')}/all-videos`, {
  params: {
    channelId: process.env.REACT_APP_JOMASHOP_YT_CHANNEL,
    limit
  }
});

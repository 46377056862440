import React, { memo } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const FooterSocialLink: React.FC<{ children: JSX.Element, url: string }> = ({ children, url }) => _isEmpty(url) === true ? <></> : (
  <a href={url} target='_blank' rel='noreferrer'>
    <span className='hidden'>Social Media</span>
    {children}
  </a>
);

interface IProps {
  links: string[];
  inverted?: boolean;
  isVideoTop?: boolean;
};

const FooterIcon: React.FC<IProps> = ({ links, inverted, isVideoTop }) => (
  <>
    <FooterSocialLink url={_get(links, 0)}>
      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36" className={`${isVideoTop ? 'opacity-20' : ''} ${inverted === true ? 'invert border-jm-white border rounded-full' : ''}`}>
        <g transform="translate(11503 -17876)">
          <g transform="translate(-11503 17876)" fill="" stroke="#15171a" strokeWidth="0.5">
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17.75" fill="none" />
          </g>
          <path d="M11.98,11.655l.575-3.75h-3.6V5.472a1.875,1.875,0,0,1,2.114-2.026h1.636V.253A19.947,19.947,0,0,0,9.8,0C6.84,0,4.9,1.8,4.9,5.047V7.905H1.609v3.75H4.9V20.72H8.957V11.655Z" transform="translate(-11492.221 17883.701)" fill="#fff" />
        </g>
      </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 1)}>
      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36" className={`${isVideoTop ? 'opacity-20' : ''} ${inverted === true ? 'invert border-jm-white border rounded-full' : ''}`}>
        <g transform="translate(11457 -17876)">
          <g transform="translate(-11457 17876)" fill="" stroke="#15171a" strokeWidth="0.5">
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17.75" fill="none" />
          </g>
          <path
            d="M 6 1.5 L 0.75 1.5 L 6.945312 9.761719 L 1.085938 18 L 3.074219 18 L 7.867188 10.988281 L 12 18 L 18 18 L 10.792969 7.890625 L 16.351562 1.5 L 14.363281 1.5 L 9.875 6.664062 Z M 12.75 15 L 3.75 3 L 5.25 3 L 14.25 15 Z M 12.75 15"
            transform="translate(-11447.996 17884.574)" fill="#fff"
          />
        </g>
      </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 2)}>
      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36" className={`${isVideoTop ? 'opacity-20' : ''} ${inverted === true ? 'invert border-jm-white border rounded-full' : ''}`}>
        <g transform="translate(11409.999 -17876)">
          <g transform="translate(-11409.999 17876)" fill="" stroke="#15171a" strokeWidth="0.5">
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17.75" fill="none" />
          </g>
          <path
            d="M4.043,18.061H.3V6H4.043ZM2.169,4.358A2.179,2.179,0,1,1,4.337,2.169,2.187,2.187,0,0,1,2.169,4.358Zm15.889,13.7H14.321v-5.87c0-1.4-.028-3.193-1.947-3.193-1.947,0-2.245,1.52-2.245,3.092v5.971H6.388V6H9.98V7.648h.052A3.935,3.935,0,0,1,13.575,5.7c3.79,0,4.486,2.5,4.486,5.737v6.624Z"
            transform="translate(-11400.604 17884.107)" fill="#fff"
          />
        </g>
      </svg>
    </FooterSocialLink>
    <FooterSocialLink url={_get(links, 3)}>
      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 36 36" className={`${isVideoTop ? 'opacity-20' : ''} ${inverted === true ? 'invert border-jm-white border rounded-full' : ''}`}>
        <g transform="translate(11364.001 -17876)">
          <g transform="translate(-11364.001 17876)" fill="" stroke="#15171a" strokeWidth="0.5">
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="17.75" fill="none" />
          </g>
          <path
            d="M8.777.457C4.362.457,0,3.4,0,8.162c0,3.029,1.7,4.75,2.736,4.75.426,0,.671-1.187.671-1.523,0-.4-1.02-1.252-1.02-2.917a5.814,5.814,0,0,1,6.04-5.911c2.93,0,5.1,1.665,5.1,4.724,0,2.284-.916,6.57-3.885,6.57A1.91,1.91,0,0,1,7.654,11.97c0-1.626,1.136-3.2,1.136-4.879,0-2.848-4.04-2.332-4.04,1.11a5.054,5.054,0,0,0,.413,2.181c-.594,2.556-1.807,6.363-1.807,9,0,.813.116,1.613.194,2.426.146.163.073.146.3.065C6.015,18.9,5.937,18.32,6.918,14.435A3.489,3.489,0,0,0,9.9,15.984c4.569,0,6.621-4.453,6.621-8.467C16.521,3.245,12.829.457,8.777.457Z"
            transform="translate(-11354.309 17883.244)" fill="#fff"
          />
        </g>
      </svg>
    </FooterSocialLink>
  </>
);

export default memo(FooterIcon);

import React, { memo } from 'react';

interface IProps {
  children: React.ReactChild;
  condition: boolean;
  className?: string;
}

const LayoutLoading: React.FC<IProps> = ({ children, condition, className = 'h-screen' }) => {
  if (condition === true) {
    return (
      <div className={`flex justify-center items-center ${className}`}>
        <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-jm-red' />
      </div>
    )
  }

  return <>{children}</>
};

export default memo(LayoutLoading);
